.slidingNav {
  border-bottom: 1px solid var(--neutral-dark-5);
  overflow-x: auto;
  white-space: nowrap;
}

.slidingNav div:first-child {
  display: flex;
}

.actions {
  width: fit-content;
}

@media screen and (max-width: 1279px) {
  .actions {
    display: none;
  }
}

@media screen and (min-width: 1279px) {
  .mobileActions {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .mobileActions {
    display: none;
  }
}

.mobileActions {
  margin-left: auto;
  width: fit-content;
  margin-top: 10px;
  max-width: 270px;
}

.slidingNav::-webkit-scrollbar {
  height: 0;
}

.navItem {
  display: inline-block;
  padding: 0 20px;
  line-height: 40px;
  border-bottom: 3px solid transparent;
  text-decoration: none;

  color: #333333;
  cursor: pointer;

  margin-right: 10px;
}

.activeItem,
.navItem:hover {
  border-bottom: 3px solid var(--orange-primary);
  color: #19415e;
}

.navItem a {
  color: unset;
  text-decoration: none;
}

.slidingNav a {
  white-space: nowrap;
}

@media screen and (min-width: 1280px) {
  .slidingNav {
    display: flex;
  }
}
