.wrapper {
  width: 100%;
    
  background-color: var(--neutral-light);
  border: 1px solid var(--neutral-dark-5);
  border-radius: 8px;
  
  padding: 20px;
}

.wrapper header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 20px;
  font-weight: bold;
  width: 40%;
}

.filters {
  width: 55%;
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.rangePicker {
  height: 40px;
  border: 1px solid var(--neutral-dark-5);
}
.wrapper section {
  width: 100%;
  height: 400px;
}
