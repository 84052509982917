.context-menu-label {
  cursor: pointer;
  width: fit-content;
  text-align: center;
}

.gm-action-wrap {
  position: relative;
  top: 0;
  right: 0px;
  height: 0;
}

.gm-action-context-menu {
  position: absolute;
  right: 0;
  border-radius: 5px;
  min-width: 150px;
  width: fit-content;
  white-space: nowrap;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  color: #555555;
  font-weight: normal;
  z-index: 1;
}

.gm-action-context-menu ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.gm-context-menu-item {
  display: block;
  padding: 5px;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.gm-context-menu-item > span {
  display: inline-block;
  padding: 0 15px;
}

.gm-context-menu-item > span:hover {
  background-color: #dedede;
  width: 100%;
}
