/**/
.wrapper {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;
  padding: 0 20px;
  background-color: #ffffff;
}

.header {
  margin-bottom: 30px;
}

.siteName {
  color: var(--blue-primary);
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
}

.formText {
  cursor: pointer;
  text-align: right;
  margin: 5px 0 10px 0;
  font-size: 14px;
  color: var(--blue-primary);
}

.formBtn {
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .formWrapper {
    width: 50%;
  }
}

@media screen and (min-width: 1280px) {
  .formWrapper {
    width: 20%;
  }
}
