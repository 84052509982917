.wrapper {
  display: inline-block;
  width: 300px;
  margin-right: 15px;

  border-radius: 8px;
  background-color: var(--neutral-light);
  border: 1px solid var(--neutral-dark-5);
  overflow: hidden;
  color: var(--blue-dark);
}

.main {
  border-bottom: 1px solid var(--neutral-dark-5);

  padding: 10px 30px 10px 20px;
}

.main > label {
  font-size: 0.8rem;
  color: var(--neutral-dark-3);
}

.main > h3 {
  color: var(--blue-primary);
  font-size: 1.75rem;
}

.bottom {
  display: flex;
  align-items: center;
  height: 40px;

  font-size: 0.75rem;
  padding-left: 20px;
  padding-top: 2px;

  background-color: var(--blue-transparent);
}

@media screen and (min-width: 1920px) {
  .wrapper {
    width: 280px;
  }

  .main > label {
    font-size: 1rem;
  }
}
