.priceItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid var(--neutral-dark-5);
  padding: 5px 10px;
}

.priceItem:hover {
  background-color: var(--neutral-dark-6);
}

.priceItem:last-child {
  border-bottom: none;
}

.formSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addIcon {
  color: var(--blue-primary);
}

.addIcon:hover {
  background-color: var(--blue-transparent);
  border-radius: 50%;
}

.trashIcon {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trashIcon:hover {
  color: var(--danger);
}

.addIcon,
.editIcon .trashIcon {
  cursor: pointer;
}
