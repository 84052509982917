.gm-spinner-15 {
  width: 24px;
  height: 24px;
}

@keyframes ldio-blvdbd1yqg {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ldio-blvdbd1yqg div {
  box-sizing: border-box !important;
}

.ldio-blvdbd1yqg > div {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 14px;
  left: 14px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: #ffffff transparent #ffffff transparent;
  animation: ldio-blvdbd1yqg 0.6666666666666666s linear infinite;
}

.ldio-blvdbd1yqg > div:nth-child(2) {
  border-color: transparent;
}

.ldio-blvdbd1yqg > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}

.ldio-blvdbd1yqg > div:nth-child(2) div:before,
.ldio-blvdbd1yqg > div:nth-child(2) div:after {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 24px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 64px 0 0 #ffffff;
}

.ldio-blvdbd1yqg > div:nth-child(2) div:after {
  left: -8px;
  top: 24px;
  box-shadow: 64px 0 0 0 #ffffff;
}

.loadingio-spinner-dual-ring-jl2luvh8b9m {
  width: 24px;
  height: 24px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldio-blvdbd1yqg {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.24);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-blvdbd1yqg div {
  box-sizing: content-box;
}
