/**/
.dashboard {
  height: auto;
  overflow-y: scroll;
  padding: 0 20px 20px 20px;
}

.dashboard .primary {
  width: 100%;
}

.dashboard .secondary {
  width: 100%;
}

.dashboard .dashboard-actions-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid black */
}

.dashboard-disabled-btn button {
  background-color: #e6e6e6;
  color: #000000;
  border: none;
}

.dashboard .dashboard-actions {
  display: flex;
  justify-content: flex-end;
}

.dashboard .dashboard-actions .contacts {
  margin-right: 10px;
  text-decoration: none;
  text-transform: capitalize;
}

.graph-row {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.single-graph-row {
  max-height: 500px;
  margin: 20px 0;
}

.top-charts {
  width: 100%;
  min-height: 250px;

  border: 1px solid #d7eeff;
  border-radius: 5px;

  margin-top: 20px;
}

.top-charts > .title {
  border-bottom: 1px solid #e5e5e5;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-left: 10px;

  line-height: 35px;
}

/* gg */
.icon-style {
  color: #c4c4c4;
  border-left: 1px solid #c4c4c4;
}

.flex-direction {
  flex-direction: column;
}

.dashboard-stat-wrapper > div:first-child {
  padding-right: 0px !important;
}

@media screen and (min-width: 768px) {
  .dashboard {
    padding: 0 25px;
  }

  .dashboard .primary {
    width: 70%;
  }

  .dashboard .secondary {
    width: 30%;
    padding-left: 20px;
  }

  .dashboard .primary,
  .dashboard .secondary {
    height: 90vh;
    overflow-y: scroll;
  }

  .top-charts > .title {
    font-size: 1.25rem;
    line-height: 50px;
  }

  .flex-direction {
    flex-direction: row;
  }

  .dashboard-stat-wrapper > div:first-child {
    padding-right: 15px !important;
  }
}

@media screen and (min-width: 1280px) {
  .graph-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 10px;
  }
}
