.campaign-dashboard {
  display: grid;
  justify-content: center;
  padding: 20px;
  width: 100%;
}

.stat-summary {
  background: #fff;
  border-radius: 5px;
  font-size: 16px;
  line-height: 19px;
  width: 315px;
  max-width: 315px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.stat-summary div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 5px;
  margin-left: 5px;
}

.stat-summary div p:last-of-type,
ul li {
  color: #6b6b6b;
  word-break: break-word;
  text-align: right;
  list-style: none;
}

.stat-summary div p:first-of-type {
  white-space: nowrap;
  color: #3f3f3f;
  font-weight: normal;
}

.contact-list {
  background: #fff;
  border-radius: 5px;
  font-size: 16px;
  line-height: 19px;
  width: 315px;
  word-break: break-word;
  max-width: 315px;
  margin: 10px auto 0;
  padding: 10px 20px;
  list-style: none;
}

.contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bullet {
  background-color: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid red;
}

.contact .details {
  margin-left: 32px;
}

.contact .details p:first-of-type {
  margin-top: 5px;
  margin-bottom: 2px;
}

.empty {
  text-align: center;
  color: var(--neutral-dark-3);
}

.card-stat-control {
  width: 90%;
  margin: 7px auto 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.stat-control-button {
  white-space: nowrap;
}

.stat-controls {
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-top: 7px;
  padding-right: 20px;
}

.campaign-view {
  width: 100%;
  height: 90vh;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
}

.campaign-view iframe {
  width: 100%;
  height: 100%;
  border: none;
  z-index: 200;
}

@media screen and (min-width: 1024px) {
  .campaign-dashboard {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 5px;
  }

  .campaign-dashboard:nth-child(even) .campaign-view {
    order: -1;
    grid-column: 1 / 6;
  }
  .campaign-dashboard:nth-child(even) .stats-wrapper {
    order: 1;
    grid-column: 9 / 12;
  }

  .stats-wrapper {
    margin-top: -3px;
  }
}
