.wrapper {
    height: 100%;
    background-color: #FFFFFF !important;
}
.css-26l3qy-menu{
   right: -8px
   
}
.content {
    padding: 10px 20px;
}
.view{
    border: none !important;
    box-shadow: none;
}
.shadow-sm {
    box-shadow: none !important;
    border: none;
}
.wrapper>header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--neutral-light);
}
.campaign-dashboard {
    display: grid;
    justify-content: center;
    padding: 20px;
    width: 100%;
    border: none;
}
.stat-card-container{
    border: 1px solid #E7E7E7;
    border-radius: 5px;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
}

.stat-summary {
    background: #fff;
    border-radius: 5px;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}
.card-header{
    color: var(--neutral-dark-8);
    font-family: 'Solway';
    font-weight: 700;
    font-size: 12px;
    line-height: 14.4px;
    padding-left: 18px;
}
.item {
    color: var(--neutral-dark-0);
    font-family: 'Solway';
    font-weight: 700;
    font-size: 12px;
    margin-left: 3px;
    line-height: 14.4px;
}
.items{
    display: flex;
}
.value {
    color: var(--neutral-dark-0);
    font-family: 'Solway';
    font-weight: 400;
    font-size: 12px;
    line-height: 14.4px;
    margin-right: 5px;
}
.stat-card{
   border: 1px solid var(--neutral-light-1);
    padding: 0;
    margin: 0;
    height: 87px;
    box-shadow: none !important;
}
.stat-card-title {
    font-weight: 400;
    font-size: 12px !important;
    color: var(--neutral-dark-3);
    line-height: 14.4px;
    font-family: 'Solway';
    margin-left: 18px;
    padding-top: 20px;
}
.stat-card-value {
    font-weight: 700;
    font-size: 24px !important;
    color: var(--neutral-dark-2);
    line-height: 28.8px;
    font-family: 'Solway';
    padding-top: 7px;
    margin-left: 18px;
}
.col h5 {
    font-size: 12px;
    color: #3F3F3F;
    line-height: 14.4px;
    font-weight: 500;
    font-family: 'Solway';
}

.greenBadge,
.infoBadge,
.dangerBadge {
    border-radius: 3px;
    padding: 3.5px 10px;
    font-size: 12px;
    color: var(--neutral-light);
}

.greenBadge {
    background: var(--success);
}

.infoBadge {
    background: var(--orange-primary);
}

.dangerBadge {
    background: var(--danger);
}
@media screen and (min-width:768px){
    .card-div{
        display: grid;
        margin-top: -8px;
    }
    .stat-card{
        max-width: 100%;
        justify-content: space-between;
    }
    .col h5 {
         width: 145px;
    }
    .value{
        text-align: right !important;
        
    }
}

@media screen and (min-width: 1024px) {
    .campaign-dashboard {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 5px;
    }
    .campaign-dashboard:nth-child(even) .campaign-view {
        order: -1;
        grid-column: 1 / 6;
    }
    .value{
        margin-right: 5px;
    }

    .campaign-dashboard:nth-child(even) .stats-wrapper {
        order: 1;
        grid-column: 9 / 12;
    }
    .stats-wrapper {
        margin-top: -3px;
    }
}

@media screen and (min-width:1366px){
    .card-div {
       display: flex;
       flex-direction: row;
       max-width:69.3%; 
       column-gap: 20px;
    }
}
@media screen and (min-width:1440px) {
    .card-div{
        max-width: 70%;
    }
    .col h5{
        font-size: 14px;
    }
}
@media screen and (min-width:1700px) {
    .card-div {
            max-width: 71.4%;
        }
}