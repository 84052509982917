:root {
  --blue-dark: #00396f;
  --blue-transparent: #65b5ff41;
  --blue-primary: #2b99ff;
  --orange-primary: #ffaa2b;
  --neutral-dark-0: #000000;
  --neutral-dark-1: #252525;
  --neutral-dark-2: #3f3f3f;
  --neutral-dark-3: #a0a0a0;
  --neutral-dark-4: #c4c4c4;
  --neutral-dark-5: #dddddd;
  --neutral-dark-6: #f5f5f5;
  --neutral-dark-7: #fbfbfb;
  --neutral-dark-8:#777777;
  --neutral-light: #ffffff;
  --neutral-light-1: #E7E7E7;

  --danger: #e36b6b;
  --success: #71a05a;
  --info: #448ba2;
}

.text-blue-primary {
  color: #2b99ff;
}

.text-orange-primary {
  color: #ffaa2b;
}

.text-danger {
  color: #e36b6b;
}

.text-success {
  color: #71a05a;
}

.text-info {
  color: #448ba2;
}
