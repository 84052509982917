.wrapper {
  width: 100%;
  padding: 0;
  overflow-x: auto;
  position: relative;
  z-index: 1;
}

.slider {
  min-width: 100%;
  padding-bottom: 5px;
}

.wrapper::-webkit-scrollbar:horizontal {
  display: none;
}

.wrapper::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: var(--neutral-dark-4);
}

.sliderContainer {
  display: flex;
  overflow-x: scroll;
}

.sliderContainer > div:last-child {
  margin-right: 0 !important;
}

@media screen and (min-width: 1278px) {
  .wrapper::-webkit-scrollbar:horizontal {
    display: unset;
    height: 10px;
    border-radius: 50px;
    background-color: var(--neutral-dark-5);
  }

  .wrapper::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: var(--neutral-dark-4);
  }
}
