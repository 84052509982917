/**/

.gmCheckbox {
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border: 1px solid var(--blue-primary);
  border-radius: 5px;
}

.disabledCheckbox {
  border-color: var(--neutral-dark-4);
  cursor: not-allowed;
}

.checkboxOn {
  background-color: var(--blue-primary);
}

.menuBtnWrapper {
  display: flex;
  justify-content: center;
}

.menuBtn {
  border-radius: 5px;
  padding: 1px 10px 1px 15px;
  background-color: var(--blue-primary);
  color: #ffffff;
  display: flex;
  width: auto;
  font-size: 14px;
  font-weight: normal;
}

.menuBtn:hover {
  background-color: var(--blue-dark);
}

.disabledMenuBtn {
  background-color: transparent;
  color: var(--blue-dark);
  cursor: not-allowed;
  font-weight: bold;
  font-size: 16px;
}

.disabledMenuBtn:hover {
  background-color: transparent;
}

.menuBtn > span:first-child {
  line-height: 32px;
  margin-right: 5px;
}

.menuBtn > span:last-child {
  margin-top: 6px;
}
