.effect-automation {
  padding: 20px;
  margin-bottom: 50px;
}

.effect-sidebar {
  padding: 20px;
}

@media screen and (min-width: 1280px) {
  .effect-automation {
    margin-bottom: 50px;
    padding: 10px 20px 0 50px;
    float: left;
    width: 60%;
    min-height: 100vh;
    overflow-y: scroll;
  }

  .effect-sidebar {
    display: block;
    float: left;
    width: 40%;
    min-height: 100vh;
    padding: 20px 100px 0 50px;
  }
}
