body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: var(--neutral-dark-2);
}


div::-webkit-scrollbar,
section::-webkit-scrollbar {
  width: 2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div,
section,
input,
textarea,
select,
form {
  box-sizing: border-box;
}

div {
  width: 100%;
}

img {
  width: 100%;
}

input {
  line-height: 36px;
}

/* setting float on tables cause them to display in a row */
table {
  width: 100%;
}

.is-clickable {
  cursor: pointer;
}

.mobi-show {
  display: initial;
}

.mobi-hide {
  display: none !;
}

.mobile {
  display: initial;
}

.web {
  display: none;
}

/** LAYOUT **/
.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1280px) {
  .web {
    display: initial;
  }

  .mobile {
    display: none;
  }
}
