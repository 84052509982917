.crumb-link {
  color: inherit;
  text-decoration: none;
  padding: 0 10px;
}

.crumb-link:hover {
  color: inherit;
}

.crumb-separator {
  user-select: none;
}
