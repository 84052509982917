/**/
.item {
  display: inline-block;
  width: 100%;
  color: var(--neutral-dark-3);
}

.item > div {
  float: right;

  display: flex;
  flex-direction: row;
  padding: 7.5px 5px 5px 22.5px;
}

.item:hover > div,
.item[class~='active'] > div {
  border-right: 4px solid;
  background-color: var(--neutral-dark-6);
  color: var(--blue-primary);
  cursor: pointer;
}

.icon {
  margin-right: 15px;
}

.title {
  line-height: 28px;
}

@media screen and (min-width: 768px) {
  .item {
    padding: 5px 0 0 0;
  }

  .title {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .item {
    padding-left: 0px;
  }

  .item > div {
    width: 100%;
  }

  .title {
    display: unset;
    font-size: 14px;
    font-weight: 200;
  }
}
