.pageContent {
  width: 100%;
  margin-bottom: 20px;
  padding: 0 3%;
}

@media screen and (min-width: 1280px) {
  .pageContent {
    width: 1000px;
    margin: 20px auto;
    padding: 0;
  }
}

@media screen and (min-width: 1440px) {
  .pageContent {
    width: 1100px;
    margin: 20px auto;
  }
}

@media screen and (min-width: 1920px) {
  .pageContent {
    width: 1400px;
  }
}
