.greenBadge,
.infoBadge,
.dangerBadge {
  border-radius: 3px;
  padding: 3.5px 10px;
  font-size: 12px;
  color: var(--neutral-light);
}

.greenBadge {
  background: var(--success);
}

.infoBadge {
  background: var(--orange-primary);
}

.dangerBadge {
  background: var(--danger);
}
