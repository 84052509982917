.item {
  border-left: 1px solid var(--neutral-light);
  border-bottom: 1px solid var(--neutral-dark-6);
  background-color: var(--neutral-light);
  margin-bottom: 5px;
  word-wrap: break-word;
  cursor: pointer;
}

.item:hover {
  border-left: 1px solid var(--blue-primary);
  background-color: var(--neutral-dark-6);
}

.gmCheckbox {
  width: 20px;
  height: 20px;
  background-color: var(--neutral-light);
  border: 1px solid var(--blue-primary);
  border-radius: 5px;
}

.checkboxOn {
  background-color: var(--blue-primary);
}

.menuBtnWrapper {
  display: flex;
  justify-content: center;
}

.menuBtn {
  border-radius: 5px;
  padding: 1px 10px 1px 15px;
  background-color: var(--blue-primary);
  color: var(--neutral-light);

  display: flex;
  width: auto;
  font-size: 14px;
}

.menuBtn:hover {
  background-color: var(--blue-dark);
}

.menuBtn > span:first-child {
  line-height: 32px;
  margin-right: 5px;
}

.menuBtn > span:last-child {
  margin-top: 6px;
}

.disabledCheckbox {
  border-color: var(--neutral-dark-4);
  cursor: not-allowed;
}
