.container {
  padding: 20px;
}

@media screen and (min-width: 768px) {
  .container {
    border: 1px solid var(--neutral-dark-5);
    border-radius: 8px;
    background: var(--neutral-light);
  }
}
