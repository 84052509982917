.gm-datatable-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  border-bottom: 1px solid #d7eeff;
  padding: 10px 0;
}

.gm-datatable-item:first-child {
  padding-top: 0;
}

.gm-datatable-item > div {
  width: 87.5%;
}

.gm-datatable-item > div:first-child {
  width: 18px;
  height: 18px;

  float: left;
}

.gm-datatable-item input[type='checkbox'] {
  width: 100%;
  height: 100%;
  padding: 0;
  border: 1px solid #99ccf1;
  background-color: #ffffff;
}

.gm-datatable-item-title {
  font-size: 16px;
  font-weight: bold;
  color: #19415e;
}

.gm-datatable-item-tagline {
  font-size: 14px;
  color: #19415e;
}

.gm-datatable-item-metadata {
  font-size: 13px;
  color: hsla(205, 58%, 23%, 0.7);
}

.gm-datatable-item-badge {
  float: right;
}
